<template>
	<section class="contentManagement">
		<g-breadcrumb bread="富文本" />
		<TableContainer title="富文本">
			<!-- <template slot="button">
				<el-button type="success" @click="addTheme" class="turnBule">添加医院</el-button>
			</template> -->
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="内容" prop="value">
					<template>
						<div>
							<!-- <ueEdit :defaultMsg='themeForm.value' :config='config' ref="ue"></ueEdit> -->
							<editor v-model="themeForm.value" :uniqueId="'reply'"></editor>
							<!-- <input type="button" value="显示编辑器内容（从控制台查看）" @click="returnContent"> -->
						</div>
					</template>
				</el-form-item>
			</el-form>
			<div>
				<el-button @click="submitFrom" type="primary">确定</el-button>
				<el-button @click="resetThemeForm" type="primary">取消</el-button>
			</div>
			<!-- <div style="margin-left: 100px;margin-top: -40px;">
				<download-excel class="export-excel-wrapper" :data="json_data" name="用户订单.xlsx">
					<el-button @click="exportAll" type="primary">双击导出</el-button>
				</download-excel>
			</div> -->

		</TableContainer>


	</section>
</template>

<script>
	import editor from "../../../components/wangEditor.vue";
	import {
		weixinKeywordAdd,
	} from '@api/wenxinMenu/api_wenxinMenu';
	export default {
		components: {
			editor
		},
		data() {
			return {
				themeForm: {
					value: '',
					type: 4,
					keyword: '关注自动回复'
				},
				rules: {
					value: [{
						required: true,
						message: '请输入回答内容',
						trigger: 'blur'
					}]
				},
				options: [],
				config: {
					initialFrameWidth: null,
					initialFrameHeight: 350,
				},
			}
		},
		created() {
			
		},
		methods: {
			submitFrom(formName) {
				// this.themeForm.value = this.$refs.ue.getUEContent();
				const params = {
					...this.themeForm
				}

				weixinKeywordAdd(params).then(res => {
					this.$message.success('回复成功')
					this.resetThemeForm('ref_themeForm')
					// 保留当前页，页面跳转至 /home 页
					this.$router.push("/wenxinMenu/keywork");
				})


				// this.$refs[formName].validate(valid => {
				// 	console.log(valid, 1111)
				// 	if (!valid) {

				// 		return false
				// 	}
				// 	const params = {
				// 		...this.themeForm
				// 	}
				// 	postsAdd(params).then(res => {
				// 		this.$message.success('新增成功')
				// 		this.resetThemeForm('ref_themeForm')
				// 		this.fetchData()
				// 	})

				// })
			},
			resetThemeForm(formName) {
				this.$router.back(0);
			},

		}
	}
</script>

<style>
</style>
